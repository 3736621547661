import $ from 'jquery';
import suggestions from 'suggestions-jquery';
import { summonAlert, removeAlert } from "../components/alert/alert";
import { summonPopUp, removePopUp } from "../components/popUp/popUp";
import { addLoading, removeLoading } from '../components/loading/loading';
import {
  validateForm, maskNumber, maskSimplePhone, maskPhone, maskInternationalPhone,
  initPasswordEye, initAgreeCheckbox, initFileLoadInput, focusFirstInput,
  initSelectValidation, initChoicesValidation,
} from '../components/validator/validator';

// function requireAll(r) {
//   r.keys().forEach(r);
// }
// requireAll(require.context("../assets/icons/", true, /\.svg$/));

//------------------------------------------------------------
// 100vh hack for mobile-browsers
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});
// usage in css
// height: calc(var(--vh, 1vh) * 100);
// 100vh hack for mobile-browsers
//------------------------------------------------------------

const phoneRegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/;

// Находим ширину скролбара и узнаем на сколько добавлять отступ справа у body.
const getScrollbarWidth = () =>
  window.innerWidth - document.documentElement.clientWidth;
let checker = false;

// Функция чтобы блочить экран и давать отступ.
const getPaddingOnBody = () => {
  if (!checker) {
    const body = document.querySelector("body");
    const header = document.querySelector("header");
    const dropdown = document.querySelector(".header__dropdown");
    const promoFixed = document.querySelector(".promo__fixed");
    const Modal = document.querySelector(".Modal");
    const popUps = document.querySelectorAll(".popUp");
    const alertWrapper = document.querySelector(".alert-wrapper");

    body.style.paddingRight = `${getScrollbarWidth()}px`;

    if (header) {
      header.style.paddingRight = `${getScrollbarWidth()}px`;
    }
    if (dropdown) {
      dropdown.style.paddingRight = `${getScrollbarWidth()}px`;
    }
    if (promoFixed) {
      promoFixed.style.paddingRight = `${getScrollbarWidth()}px`;
    }
    if (Modal) {
      Modal.style.paddingRight = `${getScrollbarWidth()}px`;
    }
    if (alertWrapper) {
      alertWrapper.style.paddingRight = `${getScrollbarWidth()}px`;
    }
    if (popUps.length > 0) {
      popUps.forEach((popUp) => {
        popUp.style.paddingRight = `${getScrollbarWidth()}px`;
      });
    }
    body.classList.add("static");
    checker = true;
  }
};

// Функция чтобы снимать блокировку экрана и убирать отступ.
const getPaddingFromBody = () => {
  if (checker) {
    const body = document.querySelector("body");
    const header = document.querySelector("header");
    const dropdown = document.querySelector(".header__dropdown");
    const promoFixed = document.querySelector(".promo__fixed");
    const Modal = document.querySelector(".Modal");
    const popUps = document.querySelectorAll(".popUp");
    const alertWrapper = document.querySelector(".alert-wrapper");

    body.style.paddingRight = "";

    if (header) {
      header.style.paddingRight = "";
    }
    if (dropdown) {
      dropdown.style.paddingRight = "";
    }
    if (promoFixed) {
      promoFixed.style.paddingRight = "";
    }
    if (Modal) {
      Modal.style.paddingRight = "";
    }
    if (alertWrapper) {
      alertWrapper.style.paddingRight = "";
    }
    if (popUps.length > 0) {
      popUps.forEach((popUp) => {
        popUp.style.paddingRight = "";
      });
    }
    body.classList.remove("static");
    checker = false;
  }
};

// Скрол к полю с ошибкой.
const scrollToErrorField = (form) => {
  form.addEventListener('bouncerFormInvalid', () => {
    const firstError = form.querySelector('.validator__input--error');
    const scrollToFirstError = (error) => {
      error.style.scrollMarginTop = '140px';
      error.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    };

    setTimeout(() => {
      scrollToFirstError(firstError);
    }, 100);
  });
};

// Статус для системных сообщений "alert". 
const setStatus = (status) => {
  switch (status) {
    case 'success':
      return 'alert--green';

    case 'exclam':
      return 'alert--grey';

    case 'error':
      return 'alert--red';

    default:
      return '';
  }
};

// Дебаунс чтобы не спамили запросы.
const debounce = (cb) => {
  const DEBOUNCE_INTERVAL = 500; // ms
  let lastTimeout = null;

  return () => {
    const parameters = arguments;
    if (lastTimeout) {
      window.clearTimeout(lastTimeout);
    }

    lastTimeout = window.setTimeout(() => {
      cb.apply(null, parameters);
    }, DEBOUNCE_INTERVAL);
  };
};

// Автоматическая высота для textarea.
const onInput = (field) => {
  field.style.height = 'auto';
  field.style.height = `${field.scrollHeight}px`;
}

const setTextareaAutoHeight = (area) => {
  const textareas = document.querySelectorAll(`${area}`);
  textareas.forEach((element) => {
    element.setAttribute('style', `height:${element.scrollHeight}px; overflow-y: hidden;`);
    element.addEventListener('input', () => {
      onInput(element);
    });
  });
};

// Функция чтобы навешивать вызов модального окна заявки по кнопке.
const token = "e2eeb413a16819871f748c5de52aaaeee3059d46"; // мой личный ключ
const activateRequestButtons = (func) => {
  const buttons = document.querySelectorAll('.button-request:not(.button-request--js)');

  buttons.forEach((button) => {
    if (!button.classList.contains('button-request--js')) {
      button.classList.add('button-request--js');

      button.addEventListener('click', (evt) => {
        evt.preventDefault();
        const { type } = button.dataset;
        const { id } = button.dataset;

        const info = button.dataset;
        // for(let key in info) {
        //   console.log(`Ключ ${key} : значение ${info[key]}`);
        // }

        if (type) {
          summonPopUp(`#modal--${type}`, true);
          setTextareaAutoHeight('form textarea');
          const modal = document.querySelector(`.modal--${type}`);
          const form = modal.querySelector('form');
          const innInput = form.querySelector('#inn');

          // const inputId = form.querySelector('input[name="id"]');
          // if (id && inputId) {
          //   inputId.value = id;
          // }

          // const inputType = form.querySelector('input[name="type"]');
          // if (inputType) {
          //   inputType.value = type;
          // }

          for(let key in info) {
            form.insertAdjacentHTML('beforeend', `<input type="hidden" name=${key} value=${info[key]}>`);
          }

          if (innInput) {
            $(`.modal--${type} #inn`).suggestions({
              token: token,
              type: 'PARTY',
              minChars: 8,
              formatResult: function (value, currentValue, suggestion, options) {
      
                const that = this;
                const highlightMatches = that.highlightMatches(suggestion.data.inn, currentValue, suggestion, options);
      
                return `${value}, ИНН: ${highlightMatches}`;
              },
              /* Вызывается, когда пользователь выбирает одну из подсказок */
              onSelect: function (suggestion) {
                if (innInput) {
                  innInput.value = suggestion.data.inn;
                }
              },
      
              /* Вызывается, когда пользователь не выбрал ни одну из подсказок */
              onSelectNothing: function () {
                if (innInput) {
                  innInput.value = '';
                }
              }
            });
          }

          const validatedForm = validateForm(`.modal--${type} form`);

          const fetch = async () => {
            addLoading();
            const answer = await func(form);
            if (answer) {
              if (answer.status === 'success') {
                validatedForm.destroy();
                removePopUp(`.modal--${type}`, true);
                removeLoading();
              }
            }
          };

          form.addEventListener('bouncerFormValid', debounce(() => {
            if (func) {
              fetch();
            }
          }));
        }
      });
    }
  });
};

export {
  getPaddingOnBody,
  getPaddingFromBody,
  getScrollbarWidth,
  scrollToErrorField,
  setStatus,
  setTextareaAutoHeight,
  activateRequestButtons,
  debounce,
};
